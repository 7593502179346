import React from 'react'
import { graphql } from 'gatsby'

import KtLayout from '@solid-ui-blocks/KtLayout/KtLayout'

import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'

import { normalizeBlockContentNodes } from '@blocks-helpers'
// import Models from '@solid-ui-blocks/Modeller/Models'
// import httpService from '../../service/httpService';
// import loadsGif from '../assets/loads.gif'
import CanliDestek from '@solid-ui-blocks/CanliDestek/CanliDestek'

const AmbalajHakkindaBilinmsi = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)


  return (
    <KtLayout prp={props} cnt={content} lightmi={false} >
      <Seo title='Ambalaj Nedir Neden Gereklidir? Ayakkabı kutusu çizimi '  />
      <Divider space='5' />
      <h3 style={{ color: "black", textAlign: "center" }}>Ambalaj Nedir Neden Gereklidir?</h3>
      <div style={{ marginLeft: "20%", marginRight: "20%" }} >
        <div style={{ color: "black", fontSize: "18px" }}>&nbsp;&nbsp;&nbsp;&nbsp;Ambalaj içine konulan ürünü koruyan, en temiz ve en güvenilir koşullarda tüketiciye ulaşmasını sağlayan, taşınmasını ve depolanmasını kolaylaştıran değerli bir malzemedir Ambalaj firmanın kimliği ve imzasıdır. Ambalaj firmanın kimliği ve imzasıdır. Herkes tarafından alışılmış bir ambalaj, en çok tercih edildiği için güveni temsil eder Olumlu yönde bir izlenim için ambalaj tasarımının kusursuz olması önemlidir</div>
      </div>
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <CanliDestek></CanliDestek>
    </KtLayout>
  )
}

export const query = graphql`
  query innerpageAmbalajHakkindaBilinmsiContent { 
    allBlockContent(filter: { page: { in: ["homepage/saas-v2", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    } 
  }
`

export default AmbalajHakkindaBilinmsi
