import React from 'react'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import Layout from '@solid-ui-layout/Layout/Layout'
import Footer from '@solid-ui-blocks/Footer/Block01'
import LoginModal1 from '@solid-ui-components/LoginModal1/Login1'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import KtHead from '@solid-ui-blocks/Header/KtHead'

const KtLayout = ({ children, prp = {}, cnt = {}, lightmi = false }) => {


  return (
    <Layout prp={prp} >
      <KtHead content={cnt['header']} menuJustify='space-between' lightmi={lightmi} />
      <LoginModal1 content={cnt['authentication']} reverse />
      <ModalWithTabs content={cnt['contact']} /> 
      {children}
      <div sx={{display:"none"}}></div>
      <Footer content={cnt['footer']} />
    </Layout>
  )
}


export default WithDefaultContent(KtLayout)
