import React from 'react'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'



const KtCanliDestek = ({ children, prp = {}, cnt = {}, lightmi = false }) => {

  return (
    <div>
      
    </div>

    // <div > 
    //    <script
    //     dangerouslySetInnerHTML={{
    //       __html: `
    //       <!--Start of Tawk.to Script-->


    //       var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    //       (function(){
    //       var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    //       s1.async=true;
    //       s1.src='https://embed.tawk.to/625d11c7b0d10b6f3e6e0c99/1g0toqtcd';
    //       s1.charset='UTF-8';
    //       s1.setAttribute('crossorigin','*');
    //       s0.parentNode.insertBefore(s1,s0);
    //       })();

    //       <!--End of Tawk.to Script-->


    //     `,
    //     }}
    //   />

    // </div> 
    // <div>
    //   <script
    //     dangerouslySetInnerHTML={{
    //       __html: `
    // <!-- Start of LiveChat (www.livechatinc.com) code -->
    //     window.__lc = window.__lc || {};
    //     window.__lc.license = 13871505;
    //     ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))

    //     function onChat() {
    //       alert("ben");
    //     }

    //     `,
    //     }}
    //   />

    //   <noscript
    //     dangerouslySetInnerHTML={{
    //       __html: `
    // <a href="https://www.livechatinc.com/chat-with/13871505/" rel="nofollow">Chat with us</a>, powered by <a href="https://www.livechatinc.com/?welcome" rel="noopener nofollow" target="_blank">LiveChat</a></noscript>
    // <!-- End of LiveChat code -->
    //     `,
    //     }}
    //   />

    // </div>

  )
}


export default WithDefaultContent(KtCanliDestek)
